import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { inject } from "mobx-react";
import DatePicker from "../components/DatePicker/DatePicker";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import SelectInput from "../components/SelectInput/SelectInput";
import { ReservationStore } from "../stores/reservation.store";
import { WidgetStore } from "../stores/widget.store";
import CardHeader from "../components/CardHeader/CardHeader";
import SelectTimeInput from "../components/SelectTimeInput/SelectTimeInput";
import { Configuration, DaysOfWeek, WidgetDto } from "../models/widget.dto";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import { Trans, useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import ErrorMessage from "../components/ErrorMessage/ErrorMessage";
import { ReservationDto } from "../models/reservation.dto";

dayjs.extend(isToday);

interface FindTableFormProps {
  reservationStore?: ReservationStore;
  widgetStore?: WidgetStore;
}

const findNextDate = (widgetDto: WidgetDto | undefined, currentDate: dayjs.Dayjs): Date => {
  const mappedWeekDays: { [day: string]: number } = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  };

  const date = currentDate.add(1, "day");
  if (widgetDto?.closedWeekDays.find((day) => mappedWeekDays[day] === date.day())) {
    return findNextDate(widgetDto, date);
  } else {
    return date.toDate();
  }
};

const FindTableForm = ({ reservationStore, widgetStore }: FindTableFormProps) => {
  const currentReservation = reservationStore?.currentReservation;
  const { register, handleSubmit, control, setValue, watch } = useForm<ReservationDto>({
    defaultValues: {
      numberOfPersons: currentReservation?.numberOfPersons ? currentReservation?.numberOfPersons : 2,
      timeSlot: currentReservation?.timeSlot,
    },
  });
  const { t } = useTranslation();
  const [hasError, setHasError] = useState<{ enabled: boolean; i18nKey?: string }>({
    enabled: false,
  });
  const [isLoading, setLoading] = useState(true);
  const [showInfo, setShowInfo] = useState<{ enabled: boolean; i18nKey?: string; values?: any }>({ enabled: false });
  const [timeSlots, setTimeSlots] = useState(widgetStore?.lastLoadedTimeSlots);
  const navigate = useNavigate();
  const numberOfPersonsChange = watch("numberOfPersons");

  useEffect(() => {
    const maxGuests = widgetStore?.widget?.configuration?.maxGuests;
    const phoneNumber = widgetStore?.widget?.restaurant?.phoneNumber;
    const isToday = dayjs(watch("date")).isToday();
    const hasNoTimeSlots = timeSlots?.length === 0;

    // Check if the number of persons exceeds the maximum limit
    if (maxGuests && numberOfPersonsChange && numberOfPersonsChange > maxGuests) {
      setShowInfo({
        enabled: true,
        i18nKey: "findTableForm.maxGuestsLimitReached",
        values: {
          phoneNumber,
          maxGuests,
        },
      });
    }
    // Check if there are no time slots available for today's date
    else if (hasNoTimeSlots && isToday) {
      setShowInfo({
        enabled: true,
        i18nKey: "findTableForm.noShortTermReservation",
        values: { phoneNumber },
      });
    } else {
      setShowInfo({ enabled: false });
    }
  }, [numberOfPersonsChange]);

  const load = async () => {
    try {
      setLoading(true);
      await widgetStore?.loadWidgetSettings();

      const currentReservationDate = currentReservation?.date;
      const widget = widgetStore?.widget;

      // Determine the default date based on the current reservation or find the next available date
      const defaultDate = currentReservationDate ? currentReservationDate : findNextDate(widget, dayjs());

      setValue("date", defaultDate);
      await loadTimeSlots(defaultDate);
    } catch (error: any) {
      console.error("Error loading widget settings:", error);
      setHasError({
        enabled: true,
        i18nKey: "errorLoadingWidgetSettings",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, [widgetStore]);

  const onSubmit = (data: any) => {
    const reservation = {
      ...currentReservation,
      ...data,
      dateTime: data.timeSlot.dateTime,
      shiftId: data.timeSlot.shiftId
    };

    reservationStore?.setReservation(reservation);
    navigate(`/checkout${window.location.search}`);
  };

  const loadTimeSlots = async (date: Date) => {
    try {
      const slots = await widgetStore?.loadTimeSlots(date);
      setTimeSlots(slots ?? []);

      if (!slots?.length && dayjs(date).isToday()) {
        setShowInfo({
          enabled: true,
          i18nKey: "findTableForm.noShortTermReservation",
          values: { phoneNumber: widgetStore?.widget?.restaurant?.phoneNumber },
        });
      } else {
        setShowInfo({ enabled: false });
      }
    } catch (error) {
      console.error("Error loading time slots:", error);
      setShowInfo({
        enabled: true,
        i18nKey: "findTableForm.noShortTermReservation",
        values: { phoneNumber: widgetStore?.widget?.restaurant?.phoneNumber },
      });
    }
  };

  const renderInfo = (i18nKey: string | undefined, values: any) => {
    return (
      <div className="text-zinc-600 mt-3">
        <Trans
          i18nKey={i18nKey}
          values={values}
          components={{
            1: (
              <a className="font-bold" href={`tel:${widgetStore?.widget?.restaurant?.phoneNumber}`}>
                {widgetStore?.widget?.restaurant?.phoneNumber}
              </a>
            ),
          }}
        ></Trans>
      </div>
    );
  };

  return (
    <>
      {isLoading && <LoadingSpinner></LoadingSpinner>}
      {hasError.enabled && <ErrorMessage i18nKey={hasError.i18nKey} />}
      {!isLoading && !hasError.enabled && (
        <div className="h-screen bg-light-gray">
          <div className="flex justify-center py-12 sm:px-6 lg:px-8">
            <div className="w-full lg:w-6/12 overflow-hidden border border-solid border-slate-200 rounded-md">
              <CardHeader heading={t("cardHeaderTitle")}></CardHeader>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="overflow-hidden shadow">
                  <div className="bg-white px-4 py-6 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <SelectInput
                          register={register("numberOfPersons", {
                            required: true,
                            valueAsNumber: true,
                            min: 1,
                          })}
                          title={t("numberOfPersons")}
                          options={createSelectPersonOptions(t, widgetStore?.widget?.configuration)}
                        ></SelectInput>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="date" className="block text-sm font-medium text-zinc-600">
                          {t("date")}
                        </label>
                        <DatePicker
                          fieldName="date"
                          rules={{ required: true }}
                          control={control}
                          placeholderText={t("findTableForm.chooseDate")}
                          handleDateChange={loadTimeSlots}
                          filterDate={(date) => {
                            const weekDay = Object.values(DaysOfWeek)[date.getDay()];
                            return !widgetStore?.widget?.closedWeekDays.includes(weekDay);
                          }}
                          excludeDateIntervals={() => {
                            const closedDatesInterval = widgetStore?.widget?.closedDates?.map((d) => ({
                              start: dayjs(d.from).startOf("day").toDate(),
                              end: dayjs(d.to).startOf("day").toDate(),
                            }));

                            return closedDatesInterval ? closedDatesInterval : [];
                          }}
                        />
                      </div>
                      <div className="col-span-6">
                        {showInfo.enabled ? (
                          renderInfo(showInfo.i18nKey, showInfo.values)
                        ) : (
                          <SelectTimeInput
                            fieldName="timeSlot"
                            rules={{ required: true }}
                            label={t("findTableForm.chooseTime")}
                            control={control}
                            options={timeSlots ? timeSlots : []}
                            timezone={widgetStore?.widget?.restaurant?.timezone ?? "Europe/Vienna"}
                          ></SelectTimeInput>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="bg-white px-4 py-6 text-right sm:px-6">
                    {!showInfo.enabled && <PrimaryButton title={t("findTableForm.next")} isSubmit />}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const createSelectPersonOptions = (
  t: TFunction<"translation", undefined, "translation">,
  configuration?: Configuration
): { value: number; displayName: string; disabled: boolean }[] => {
  const options = [{ value: 0, displayName: t("findTableForm.defaultOptionSelectPersons"), disabled: true }];

  if (configuration) {
    const { minGuests, maxGuests } = configuration;
    for (let i = minGuests; i <= maxGuests; i++) {
      if (i === 1) {
        options.push({ value: i, displayName: `${i} ${t("person")}`, disabled: false });
      } else {
        options.push({
          value: i,
          displayName: `${i} ${t("persons")}`,
          disabled: false,
        });
      }
    }

    options.push({
      value: maxGuests + 1,
      displayName: `${maxGuests}+ ${t("persons")}`,
      disabled: false,
    });
  }

  return options;
};

export default inject("reservationStore", "widgetStore")(FindTableForm);
