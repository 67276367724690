import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CheckoutForm from "../pages/CheckoutForm";
import CompletedPage from "../pages/CompletedPage";
import FindTableForm from "../pages/FindTableForm";
import PublicAction from "../pages/PublicUserAction";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<FindTableForm />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/completed" element={<CompletedPage />} />
        <Route path="/public" element={<PublicAction />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
