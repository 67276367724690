import React from "react";

interface SelectInputProps {
  register?: any;
  title?: string | undefined | null;
  className?: string;
  options: { value: any; displayName: string; disabled?: boolean }[];
}

const SelectInput = ({ register, title, className, options }: SelectInputProps) => {
  return (
    <div>
      {title ? (
        <label htmlFor={title} className="block text-sm font-medium text-zinc-600">
          {title}
        </label>
      ) : (
        ""
      )}

      <select
        {...register}
        className={`block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:borderbg-primary focus:outline-none focus:ringbg-primary sm:text-sm ${className}`}
      >
        {options.map((o) => (
          <option value={o.value} key={o.value} disabled={o.disabled}>
            {o.displayName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
