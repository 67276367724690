import React from "react";

interface TextFieldProps {
  register: any;
  title: string;
  placeholder?: string;
  disabled?: boolean;
  hideTitle?: boolean;
  type?: string;
}

const TextField = ({
  register,
  title,
  disabled,
  placeholder,
  hideTitle,
  type = "text",
}: TextFieldProps) => {
  return (
    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ringbg-primary focus-within:borderbg-primary">
      {!hideTitle && (
        <label
          htmlFor="name"
          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-zinc-600"
        >
          {title}
        </label>
      )}
      <input
        {...register}
        disabled={disabled}
        type={type}
        className="block w-full border-0 p-0 text-zinc-600 placeholder-gray-500 focus:ring-0 sm:text-sm focus:outline-none"
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextField;
