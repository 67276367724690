import React from "react";

interface PrimaryButtonProps {
  title: string;
  onClick?: () => void;
  className?: any;
  isSubmit?: boolean;
}

const PrimaryButton = ({ title, onClick, className, isSubmit }: PrimaryButtonProps) => {
  if (isSubmit) {
    return (
      <button
        type="submit"
        className={`inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary hover:bgbg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ringbg-primary ${className}`}
      >
        {title}
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      type="button"
      className={`inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary hover:bgbg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ringbg-primary ${className}`}
    >
      {title}
    </button>
  );
};

export default PrimaryButton;
