export interface WidgetDto {
  shifts: Shift[];
  closedWeekDays: DaysOfWeek[];
  closedDates: {
    from: Date;
    to: Date;
  }[];
  configuration: Configuration;
  restaurant: Restaurant;
}

export interface Shift {
  _id: string;
  restaurant: string;
  name: string;
  enabled: boolean;
  daysOfWeek: DaysOfWeek[];
  firstSeating: Date;
  lastSeating: Date;
  startDate: Date;
  endDate: Date;
  minGuests: number;
  maxGuests: number;
  interval: number;
  capacity: number;
  duration: number;
  notes: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Configuration {
  _id: string;
  restaurant: string;
  name: string;
  color: string;
  logo: string;
  info: string;
  emailRequired: boolean;
  phoneRequired: boolean;
  enabled: boolean;
  minGuests: number;
  maxGuests: number;
}

export interface Address {
  street: string;
  zip: string;
  city: string;
}

export interface Restaurant {
  name: string;
  email: string;
  phoneNumber: string;
  address: Address;
  timezone: string;
}

export enum DaysOfWeek {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}
