import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Trans } from "react-i18next";

type Props = {
  i18nKey?: string;
  values?: Record<string, unknown>;
  components?: readonly React.ReactElement[] | { readonly [tagName: string]: React.ReactElement };
};

const ErrorMessage = ({ i18nKey, values, components }: Props) => {
  return (
    <div className="h-screen bg-light-gray">
      <div className="flex justify-center py-12 sm:px-6 lg:px-8">
        <div className="w-full lg:w-6/12 overflow-hidden border border-solid border-slate-200 rounded-md">
          <div className="flex flex-col items-center justify-center bg-white px-4 py-6 sm:px-6 text-center">
            <div className="mr-2">
              <ExclamationTriangleIcon className="h-12 w-12 text-red-500" />
            </div>
            <p className="text-center text-gray-500 mx-auto max-w-[430px]">
              <Trans i18nKey={i18nKey} values={values} components={components}/>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
