import axios from "axios";
const API_URL = process.env.REACT_APP_API;

const baseURL = API_URL;
const instance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});

instance.interceptors.request.use((config) => {
  return new Promise(async (resolve, reject) => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("apiKey") && config.headers) {
      config.headers = {
        ...config.headers,
        "X-API-Key": "" + params.get("apiKey"),
      };
    }
    resolve(config);
  });
});

export default instance;
