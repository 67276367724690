export const de = {
  errorLoadingWidgetSettings: "Failed to load widget settings: API key missing or invalid.",
  errorNoOnlineReservationsPossible: "Derzeit sind leider keine Online-Reservierungen möglich. Bitte kontaktieren Sie uns unter <1>{{phoneNumber}}</1>.",
  numberOfPersons: "Anzahl Gäste",
  date: "Datum",
  person: "Person",
  persons: "Personen",
  cardHeaderTitle: "Jetzt Tisch reservieren",
  checkoutForm: {
    time: "Uhrzeit",
    firstName: "Vorname",
    firstNameRequired: "Das Feld „Vorname” ist erforderlich.",
    lastName: "Nachname",
    lastNameRequired: "Das Feld „Nachname” ist erforderlich.",
    email: "E-Mail",
    emailRequired: "Das Feld „E-Mail” ist erforderlich.",
    emailInvalid: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
    phoneNumber: "Telefonnummer",
    phoneNumberRequired: "Das Feld „Telefonnummer” ist erforderlich.",
    phoneNumberInvalid: "Bitte geben Sie eine gültige Telefonnummer ein. z.B. +43 123 4567890",
    note: "Anmerkung",
    submit: "Anfrage abschicken",
  },
  findTableForm: {
    chooseDate: "Datum auswählen",
    chooseTime: "Uhrzeit auswählen",
    defaultOptionSelectPersons: "Anzahl auswählen",
    noShortTermReservation:
      "Kurzfristige Reservierungen sind nur telefonisch möglich. Bitte kontaktieren Sie uns unter <1>{{phoneNumber}}</1>.",
    next: "Weiter",
    maxGuestsLimitReached: "Reservierungen für mehr als {{maxGuests}} Personen nehmen wir gerne  unter <1>{{phoneNumber}}</1> entgegen.",
  },
  completedPage: {
    title: "Vielen Dank für Ihre Anfrage!",
    text: "Wir freuen uns, Ihnen mitteilen zu können, dass Ihre Reservierungsanfrage erfolgreich übermittelt wurde. Sie erhalten in Kürze eine Bestätigungsmail. Sollten Sie keine Bestätigungsmail erhalten, rufen Sie uns bitte an:",
    subtext:
      "Bitte überprüfen Sie auch Ihren Spam-Ordner, um sicherzustellen, dass unsere Nachrichten nicht versehentlich als Spam eingestuft wurden."
  }
};
