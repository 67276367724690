import { observer } from "mobx-react";
import React, { ReactElement } from "react";
import widgetStore from "../stores/widget.store";

function CSSInjector(): ReactElement {
  let primaryColor = "#000000";

  function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  if (widgetStore && widgetStore.widget?.configuration) {
    primaryColor = widgetStore.widget?.configuration.color;
  }

  let colorInRGB = hexToRgb(primaryColor);

  if (!colorInRGB) {
    colorInRGB = { r: 0, g: 0, b: 0 };
  }

  const css = `
    @layer base {
        :root {
          /* For rgb(255 115 179 / <alpha-value>) */
          --color-primary: rgb(${colorInRGB.r} ${colorInRGB.g} ${colorInRGB.b});
        }
      }
    `;

  return (
    <>
      <style>{css}</style>
    </>
  );
}

export default observer(CSSInjector);
