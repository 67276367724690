import { action, makeAutoObservable, observable } from "mobx";
import Axios from "../Axios";
import { ReservationDto } from "../models/reservation.dto";

export class ReservationStore {
  @observable currentReservation: ReservationDto | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setReservation(reservation: ReservationDto) {
    this.currentReservation = reservation;
  }

  @action
  async create(data: ReservationDto) {
    const response = await Axios.post("/public/reservations", data);
    this.currentReservation = undefined;
    if (response.status === 201) {
      return true;
    } else {
      return false;
    }
  }

  @action
  async cancelReservation(key: string) {
    try {
      const response = await Axios.get(`/public/reservations?mode=cancel&key=${key}`);

      return response.status;
    } catch (e:any) {
      return e.response.status;
    }
  }
}

const reservationStore = new ReservationStore();
export default reservationStore;
