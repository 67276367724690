import React from "react";
import RDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { Controller } from "react-hook-form";
registerLocale("de", de);

interface Props {
  fieldName: string;
  rules: any;
  control: any;
  disabled?: boolean;
  defaultValue?: Date;
  placeholderText?: string | undefined | null;
  filterDate?: (date: Date) => boolean;
  handleDateChange?: any;
  excludeDateIntervals: () => { start: Date; end: Date }[];
}

const DatePicker = ({
  control,
  rules,
  fieldName,
  disabled,
  defaultValue,
  placeholderText,
  filterDate,
  handleDateChange,
  excludeDateIntervals,
}: Props) => {
  return (
    <Controller
      name={fieldName}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
      render={({ field }) => (
        <RDatePicker
          disabled={disabled}
          minDate={new Date()}
          placeholderText={placeholderText ? placeholderText : ""}
          className="cursor-pointer w-full relative border border-gray-300 focus:outline-none rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ringbg-primary focus-within:borderbg-primary sm:text-sm text-zinc-600 placeholder-gray-500"
          selected={field.value}
          wrapperClassName="w-full"
          locale="de"
          dateFormat="dd.MM.yyyy"
          filterDate={filterDate}
          excludeDateIntervals={[...excludeDateIntervals()]}
          {...field}
          onChange={(value) => {
            field.onChange(value);
            handleDateChange(value);
          }}
          onFocus={(e) => e.target.blur()}
        />
      )}
    />
  );
};

export default DatePicker;
