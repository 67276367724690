import { action, makeAutoObservable, observable } from "mobx";
import Axios from "../Axios";
import { WidgetDto } from "../models/widget.dto";
import dayjs from "dayjs";
import { TimeSlotDto } from "../models/time-slot.dto";

export class WidgetStore {
  @observable widget: WidgetDto | undefined;
  @observable lastLoadedTimeSlots: TimeSlotDto[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async loadWidgetSettings() {
    const apiKey = this.getApiKey();

    if (!apiKey) {
      throw new Error("No API key provided");
    }

    const response = await Axios.get(`/widgets/${apiKey}/settings`);
    if (response.status === 200) {
      this.widget = response.data;
      document.title = this.widget?.restaurant?.name
        ? `Reservierung Restaurant ${this.widget?.restaurant?.name}`
        : "essen24.at";
    }
  }

  @action
  async loadTimeSlots(date: Date): Promise<TimeSlotDto[]> {
    const apiKey = this.getApiKey();
    const response = await Axios.get(`/widgets/${apiKey}/slots?date=${dayjs(date).format("YYYY-MM-DD")}`);
    this.lastLoadedTimeSlots = response.data;

    return response.data;
  }

  getApiKey = () => {
    const params = new URLSearchParams(window.location.search);

    return params.get("apiKey");
  };
}

const widgetStore = new WidgetStore();
export default widgetStore;
