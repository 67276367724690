import { inject } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";
import { WidgetStore } from "../stores/widget.store";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../components/ErrorMessage/ErrorMessage";

interface CompletedPageProps {
  widgetStore?: WidgetStore;
}

export const CompletedPage = ({ widgetStore }: CompletedPageProps) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { success } = state ? (state as { success: boolean }) : { success: false };
  const restaurant = widgetStore?.widget?.restaurant;

  return (
    <>
      {success && (
        <div className="h-screen bg-light-gray">
          <div className="flex justify-center py-12 sm:px-6 lg:px-8">
            <div className="w-full lg:w-6/12 overflow-hidden border border-solid border-slate-200 rounded-md">
              <div className="bg-white px-4 py-6 sm:px-6 text-center">
                <h1 className="text-4xl font-bold">{t("completedPage.title")}</h1>
                <p className="mt-6">
                  {t("completedPage.text")} (
                  <a className="font-bold" href={"tel:" + restaurant?.phoneNumber}>
                    {restaurant?.phoneNumber}
                  </a>
                  ).
                </p>
                <p className="text-red-500 mt-12">{t("completedPage.subtext")}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {!success && (
        <ErrorMessage
          i18nKey="errorNoOnlineReservationsPossible"
          values={{ phoneNumber: restaurant?.phoneNumber }}
          components={{
            1: (
              <a className="font-bold" href={`tel:${restaurant?.phoneNumber}`}>
                {restaurant?.phoneNumber}
              </a>
            ),
          }}
        />
      )}
    </>
  );
};

export default inject("widgetStore")(CompletedPage);
