import React from "react";
import SelectInput from "../SelectInput/SelectInput";

interface CardHeaderProps {
  heading: string;
}

const CardHeader = ({ heading }: CardHeaderProps) => {
  return (
    <div className="flex items-center bg-primary px-4 py-3 sm:px-6">
      <div className="text-lg font-medium leading-6 text-white">{heading}</div>

      {/*
      --> Use Browser language and i18n Library
      <div className="ml-auto w-16">
        <SelectInput
          className="!py-1 !px-2 !rounded-none"
          options={[
            { value: "DE", displayName: "DE" },
            { value: "EN", displayName: "EN" },
          ]}
        ></SelectInput>
      </div>*/}
    </div>
  );
};

export default CardHeader;
