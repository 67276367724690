import React from "react";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { TimeSlotDto } from "../../models/time-slot.dto";

dayjs.extend(utc);
dayjs.extend(timezone);

interface SelectTimeInputProps {
  control: any;
  rules: any;
  label: string | undefined | null;
  fieldName: string;
  options: TimeSlotDto[];
  timezone: string
}

const SelectTimeInput = ({ fieldName, rules, label, control, options, timezone }: SelectTimeInputProps) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <div>
            {options.length > 1 ? (
              <label className="block text-sm font-medium text-zinc-600 mb-2">{ label }</label>
            ) : (
              ""
            )}
            <div className="flex gap-3 justify-start flex-wrap">{renderBadges(options, onChange, value, timezone)}</div>
          </div>
        );
      }}
    />
  );
};

const renderBadges = (
  options: TimeSlotDto[],
  onChange: any,
  selectedSlot: TimeSlotDto,
  timezone: string
) => {
  return options.map((option) => {
    const active =
      "cursor-pointer inline-flex items-center rounded-md bg-primary w-16 justify-center py-1 text-sm font-medium text-white hover:bg-primary hover:text-white";
    const inactive =
      "cursor-pointer inline-flex items-center rounded-md bg-gray-100 w-16 justify-center py-1 text-sm font-medium text-gray-800 hover:bg-primary hover:text-white";

    const dateTime = dayjs(option.dateTime).tz(timezone);
    const time = dateTime.format("HH:mm");

    const selectedDate = dayjs(selectedSlot?.dateTime).tz(timezone);
    const selectedTime = selectedDate.format("HH:mm");

    const isSelected = time === selectedTime;

    return (
      <span onClick={() => onChange({ dateTime, shiftId: option.shiftId })} key={time} className={isSelected ? active : inactive}>
        {time}
      </span>
    );
  });
};

export default SelectTimeInput;
