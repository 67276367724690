import { Provider } from "mobx-react";
import React from "react";
import "./App.css";
import AppRouter from "./routing/AppRouter";
import { stores } from "./stores";
import CSSInjector from "./theme/CSSInjector";

function App() {
  return (
    <Provider {...stores}>
      <CSSInjector />
      <AppRouter />
    </Provider>
  );
}

export default App;
