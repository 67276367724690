import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Gleap from "gleap";

import "./i18n";

if (process.env.REACT_APP_ENABLE_GLEAP && process.env.REACT_APP_ENABLE_GLEAP === "true") {
  Gleap.initialize("zNPlhawZuJngA5gGqml5QcprNCMULpDM");
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
